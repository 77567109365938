<!-- 绑定手机号 -->
<template>
	<div class="main-content main-normal">
		<el-form ref="form" class="info-form" :model="form" :rules="rules" label-position="left" label-width="80px">
			<el-form-item label="旧手机号" prop="mobile">
				<el-input v-model="form.mobile" :disabled="true" placeholder="请输入旧手机号"></el-input>
			</el-form-item>
			<el-form-item label="新手机号" prop="newMobile">
				<el-input v-model="form.newMobile" placeholder="请输入新手机号" @input="validatePhone"></el-input>
				<span style="color:#999">（艺集网账号会同步更新）</span>
			</el-form-item>
			<el-form-item label="验证码" prop="verCode">
				<el-input v-model="form.verCode" placeholder="请输入手机验证码"></el-input>
				<el-button type="primary" class="vercode-btn" :disabled="!disabled" v-show="show" v-preventReClick @click="getVerifyCode">发送验证码</el-button>
				<span v-show="!show" class="count">{{count}} s</span>
			</el-form-item>			
		</el-form>
		<el-button type="primary" class="pwdbtn" v-preventReClick @click="onSubmit">确定</el-button>
	</div>
</template>

<script>
import { phoneRules } from "@/utils/rule.js"
import { setToken } from "@/utils/auth";
	export default {
		data() {
			return {
				active: 1,
				disabled: false,
				show: true,
				count: '',
				timer: null,
				form: {
					mobile: '',
					newMobile:'',
					verCode: '',
				},
				rules: {
					mobile:[
						{required: true, message: '请输入手机号', trigger: 'blur' },
						{ validator: phoneRules, trigger: ['change','blur'] }
					],	
					newMobile:[
						{required: true, message: '请输入手机号', trigger: 'blur' },
						{ validator: phoneRules, trigger: ['change','blur'] }
					],			
				},
			};
		},
		created(){
			this.getMemberMobile();
		},
		methods: {
			//获取旧手机号
			getMemberMobile(){
				this.$http
					.get("/api/web/my/getMemberMobile")
					.then((res) => {
						this.form.mobile = res.data.mobile
					});
			},		
    	// 发送验证码倒计时			
			getCode(){
				const TIME_COUNT = 60;
				if (!this.timer) {
					this.count = TIME_COUNT;
					this.show = false;
					this.timer = setInterval(() => {
					if (this.count > 0 && this.count <= TIME_COUNT) {
						this.count--;
						this.disabled = false;
						} else {
						this.show = true;
						clearInterval(this.timer);
						this.disabled = true;
						this.timer = null;
						}
					}, 1000)
					}
			},
			rightPhone(){
				//利用正则对手机号匹配
				return /^1[3456789]\d{9}$/.test(this.form.newMobile);
			},
			validatePhone(){
				if (this.rightPhone()) {
					// console.log(1)
					this.disabled = true;
				} else {
					// console.log(2)
					this.disabled = false;
				}
			},
			// 请求短信接口
			getVerifyCode() {
				const data = {
					mobile: this.form.newMobile,
				}
				if (!this.errors) {
					this.$http
					.post("/api/web/my/getUpdateMobileCode", data)
					.then((res) => {
						this.getCode();					
					}).catch((err) => {
            this.$toast(err.msg);
          })
					
				}
			},
			//提交修改
			onSubmit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						// this.$toast('通过')
						const data = {
							mobile: this.form.newMobile,
							verCode: this.form.verCode
						}
						this.$http						
							.post('/api/web/my/postEditMemberMobile', data)
							.then((res) => {
								this.$toast(res.msg || "手机号修改成功");
								setToken(res.data.token);
								setTimeout(() => {
									this.$store.commit("SET_USER_INFO", res.data);
									this.$router.replace(this.redirect || "/");
								}, 100);
							})
							.catch(err => {
								this.$toast(err.msg || "登录失败");
							})
					} else {
						// this.$toast('请填写完整表单')
						return false;
					}
				});
			},
			
		}
	};
</script>

<style lang='less' scoped>
	.main-content {
		text-align: left;
		min-height: 680px;
		padding: 30px 30px 40px;

		&.main-normal {
			background-color: white;

			/deep/.el-form-item {
				.el-form-item__label {
					&::before {
						display: none;
					}
				}
				.vercode-btn {
					width: 120px;
					background: #d71a18;
					border: 1px solid #dadada;
					color: #fff;
					border-radius: 4px;
					&.is-disabled {
						color: #5f5f5f;
    				background: #dddddd;
						border: 1px solid #dadada;
					}		
				}
				.count{
					padding: 10px 20px;
					color: #5f5f5f;
					background: #dddddd;
					border-radius: 4px;
					border: 1px solid #dadada;
				}
				.el-form-item__content {
					text-align: left;
					.el-input {
						text-align: left;
						width: 260px;
						margin-right: 20px;
						&.is-disabled{
							color: #606266;
							background-color: #fff;
							.el-input__inner{
								color: #606266;
								background-color: #fff;
							}
						}
					}
				}
			}
			.pwdbtn {
				width: 160px;
				height: 44px;
				margin-top: 10px;
				margin-bottom: 15px;
				background: #D71A18;
				border-radius: 4px;
				margin-left: 80px;
				&.is-disabled {
					background: #DADADA;
					border: 1px solid #DADADA;
				}
			}		
		}
	}
</style>
